import { create } from 'zustand';
import { getPageStaticAllService } from '~/pages/api/extends/pages';
import { StaticAllData } from '~/pages/api/extends/pages/types';
import { getGeneralService, getHeaderFooterService } from '~/pages/api/extends/systems';
import {
  FooterDataTypes,
  GeneralDataTypes,
  HeaderDataTypes,
} from '~/pages/api/extends/systems/types';

type InitialStateTypes = {
  loading: boolean;
  success: boolean;
  error: boolean;
  data?: GeneralDataTypes;
  footer?: FooterDataTypes;
  header?: HeaderDataTypes;
  staticAll?: StaticAllData[];
};

type Actions = {
  getGeneral: () => void;
  getHeaderFooter: () => void;
  getStaticPageAll: () => void;
}

const initialState: InitialStateTypes = {
  loading: false,
  success: false,
  error: false,
  data: undefined,
  footer: undefined,
  header: undefined,
  staticAll: undefined,
};

export const useSystem = create<InitialStateTypes & Actions>((set) => {
  return {
    ...initialState,
    getGeneral: async () => {
      set({ loading: true });
      try {
        const data = await getGeneralService();
        set((state) => ({
          ...state,
          data,
        }));
      } catch (error) {
        set({ error: true });
      } finally {
        set({ loading: false });
      }
    },
    getHeaderFooter: async () => {
      set({ loading: true });
      try {
        const data = await getHeaderFooterService();
        set((state) => ({
          ...state,
          footer: data.footer,
          header: data.header,
        }));
      } catch (error) {
        set({ error: true });
      } finally {
        set({ loading: false });
      }
    },
    getStaticPageAll: async () => {
      try {
        const data = await getPageStaticAllService();
        set((state) => ({
          ...state,
          staticAll: data,
        }));
      } catch (error) {
        set({ error: true });
      }
    },
  };
});

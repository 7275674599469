import { PREFIX_SD } from './constants';

export function getLangWithUrl(slug?: string) {
  // if (i18n.language && i18n.language !== 'vi') return `/${i18n.language}/${slug}`;
  return slug ? `/${slug}` : '/';
}

export function getSdCloudUrl(slug?: string) {
  // if (i18n.language && i18n.language !== 'vi') return `/${i18n.language}/${slug}`;
  return slug ? `${PREFIX_SD}/${slug}` : `${PREFIX_SD}`;
}

export function mapModifiers(
  baseClassName: string,
  ...modifiers: (string | string[] | false | undefined)[]
): string {
  return modifiers
    .reduce<string[]>(
      (acc, m) => (!m ? acc : [...acc, ...(typeof m === 'string' ? [m] : m)]),
      [],
    )
    .map((m) => `-${m}`)
    .reduce<string>(
      (classNames, suffix) => `${classNames} ${baseClassName}${suffix}`,
      baseClassName,
    );
}
